interface IAppStoreLogoProps {
  className?: string;
}

export const AppStoreLogo = ({ className }: IAppStoreLogoProps) => {
  return (
    <svg
      className={className}
      width="128"
      height="37"
      viewBox="0 0 128 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M127.289 32.7138C127.289 34.6844 125.659 36.2806 123.644 36.2806H4.36067C2.34614 36.2806 0.710938 34.6844 0.710938 32.7138V4.26971C0.710938 2.30005 2.34614 0.698242 4.36067 0.698242H123.643C125.659 0.698242 127.288 2.30005 127.288 4.26971L127.289 32.7138Z"
        fill="black"
      />
      <path
        d="M123.259 0.740473C125.454 0.740473 127.24 2.4819 127.24 4.62199V32.3553C127.24 34.4954 125.454 36.2368 123.259 36.2368H4.74074C2.54578 36.2368 0.759703 34.4954 0.759703 32.3553V4.62199C0.759703 2.4819 2.54578 0.740473 4.74074 0.740473H123.259ZM123.259 -0.000236499H4.74074C2.13452 -0.000236499 0 2.08092 0 4.62199V32.3553C0 34.8964 2.13452 36.9775 4.74074 36.9775H123.259C125.865 36.9775 128 34.8964 128 32.3553V4.62199C128 2.08092 125.865 -0.000236499 123.259 -0.000236499Z"
        fill="#A6A6A6"
      />
      <path
        d="M28.5656 18.289C28.5381 15.3095 31.0678 13.86 31.1834 13.7925C29.7508 11.756 27.5302 11.4777 26.7499 11.4555C24.8849 11.2642 23.0758 12.5436 22.1258 12.5436C21.1568 12.5436 19.6938 11.474 18.117 11.5054C16.088 11.5359 14.1898 12.6813 13.1487 14.46C11.0002 18.0866 12.6026 23.416 14.661 26.3474C15.6907 27.783 16.8939 29.386 18.4688 29.3296C20.0095 29.2677 20.585 28.3719 22.4444 28.3719C24.2866 28.3719 24.8271 29.3296 26.4332 29.2936C28.0868 29.2677 29.1279 27.8515 30.1215 26.4029C31.3114 24.7573 31.7893 23.1368 31.8083 23.0536C31.7694 23.0407 28.5969 21.8601 28.5656 18.289Z"
        fill="white"
      />
      <path
        d="M25.5315 9.52712C26.3602 8.51671 26.9272 7.14206 26.7698 5.74707C25.5704 5.79884 24.0704 6.55596 23.2067 7.54419C22.4425 8.41502 21.7598 9.84236 21.9362 11.1847C23.2835 11.2826 24.6668 10.5218 25.5315 9.52712Z"
        fill="white"
      />
      <path
        d="M50.8639 29.1248H48.7106L47.5311 25.5111H43.4313L42.3078 29.1248H40.2114L44.2733 16.8223H46.7821L50.8639 29.1248ZM47.1756 23.995L46.1089 20.7826C45.9961 20.4544 45.7846 19.6816 45.4727 18.465H45.4348C45.3106 18.9882 45.1105 19.7611 44.8355 20.7826L43.7878 23.995H47.1756V23.995Z"
        fill="white"
      />
      <path
        d="M61.3097 24.5798C61.3097 26.0885 60.8916 27.281 60.0553 28.1565C59.3063 28.9358 58.3761 29.325 57.2658 29.325C56.0674 29.325 55.2065 28.9053 54.6821 28.0659H54.6442V32.739H52.6228V23.1737C52.6228 22.2252 52.5972 21.2518 52.5479 20.2534H54.3256L54.4385 21.6595H54.4764C55.1505 20.6001 56.1736 20.0713 57.5465 20.0713C58.6198 20.0713 59.5158 20.4845 60.2326 21.3119C60.9513 22.1402 61.3097 23.2292 61.3097 24.5798ZM59.2503 24.6519C59.2503 23.7885 59.0512 23.0767 58.6511 22.5164C58.214 21.9322 57.6271 21.6401 56.8913 21.6401C56.3926 21.6401 55.9394 21.8028 55.5345 22.1236C55.1287 22.4471 54.8632 22.8696 54.739 23.3928C54.6764 23.6369 54.6452 23.8366 54.6452 23.9937V25.4728C54.6452 26.1181 54.8481 26.6626 55.2539 27.1072C55.6597 27.5519 56.1868 27.7738 56.8354 27.7738C57.5967 27.7738 58.1893 27.4872 58.6132 26.9159C59.0379 26.3436 59.2503 25.5893 59.2503 24.6519Z"
        fill="white"
      />
      <path
        d="M71.7746 24.5798C71.7746 26.0885 71.3564 27.281 70.5192 28.1565C69.7711 28.9358 68.841 29.325 67.7307 29.325C66.5322 29.325 65.6713 28.9053 65.148 28.0659H65.11V32.739H63.0886V23.1737C63.0886 22.2252 63.063 21.2518 63.0137 20.2534H64.7915L64.9043 21.6595H64.9422C65.6154 20.6001 66.6384 20.0713 68.0123 20.0713C69.0847 20.0713 69.9807 20.4845 70.6994 21.3119C71.4152 22.1402 71.7746 23.2292 71.7746 24.5798ZM69.7152 24.6519C69.7152 23.7885 69.5151 23.0767 69.115 22.5164C68.6779 21.9322 68.0929 21.6401 67.3562 21.6401C66.8565 21.6401 66.4043 21.8028 65.9984 22.1236C65.5926 22.4471 65.3281 22.8696 65.2039 23.3928C65.1423 23.6369 65.11 23.8366 65.11 23.9937V25.4728C65.11 26.1181 65.3129 26.6626 65.7168 27.1072C66.1226 27.551 66.6498 27.7738 67.3003 27.7738C68.0616 27.7738 68.6542 27.4872 69.078 26.9159C69.5028 26.3436 69.7152 25.5893 69.7152 24.6519Z"
        fill="white"
      />
      <path
        d="M83.4744 25.6743C83.4744 26.7208 83.1018 27.5722 82.3537 28.2295C81.5316 28.9478 80.3872 29.3065 78.9167 29.3065C77.5589 29.3065 76.4704 29.0513 75.6465 28.5401L76.1149 26.8974C77.0023 27.4206 77.9761 27.6832 79.0371 27.6832C79.7984 27.6832 80.391 27.5149 80.8167 27.1803C81.2406 26.8456 81.452 26.3963 81.452 25.8361C81.452 25.3369 81.2775 24.9163 80.9277 24.5752C80.5797 24.2341 79.9985 23.917 79.1869 23.6239C76.9777 22.8206 75.874 21.6438 75.874 20.0963C75.874 19.0849 76.2609 18.2557 77.0355 17.6104C77.8073 16.9642 78.837 16.6416 80.1246 16.6416C81.2728 16.6416 82.2266 16.8367 82.988 17.2259L82.4826 18.8325C81.7715 18.4554 80.9675 18.2668 80.0677 18.2668C79.3566 18.2668 78.801 18.4378 78.4028 18.778C78.0662 19.0821 77.8974 19.4528 77.8974 19.8919C77.8974 20.3782 78.0899 20.7803 78.4767 21.0965C78.8133 21.3886 79.4249 21.7048 80.3123 22.0459C81.398 22.4721 82.1953 22.9703 82.7083 23.5417C83.2193 24.1111 83.4744 24.8239 83.4744 25.6743Z"
        fill="white"
      />
      <path
        d="M90.1582 21.7324H87.93V26.0394C87.93 27.1349 88.3225 27.6822 89.1095 27.6822C89.4708 27.6822 89.7704 27.6516 90.0074 27.5906L90.0633 29.0873C89.6651 29.2324 89.1408 29.3055 88.4913 29.3055C87.693 29.3055 87.0691 29.0679 86.6187 28.5937C86.1703 28.1185 85.9446 27.3216 85.9446 26.2021V21.7306H84.6172V20.2515H85.9446V18.6272L87.93 18.043V20.2515H90.1582V21.7324Z"
        fill="white"
      />
      <path
        d="M100.211 24.6159C100.211 25.9795 99.8113 27.099 99.013 27.9744C98.1758 28.8757 97.0645 29.325 95.6793 29.325C94.3443 29.325 93.2814 28.8933 92.4888 28.0299C91.6961 27.1665 91.2998 26.0765 91.2998 24.7629C91.2998 23.3882 91.7075 22.2623 92.5258 21.3868C93.3421 20.5105 94.4439 20.0723 95.8291 20.0723C97.1641 20.0723 98.2383 20.504 99.049 21.3683C99.8246 22.2068 100.211 23.2893 100.211 24.6159ZM98.1141 24.6797C98.1141 23.8616 97.9349 23.1599 97.5718 22.5747C97.148 21.8666 96.5421 21.5135 95.757 21.5135C94.9445 21.5135 94.3272 21.8675 93.9034 22.5747C93.5403 23.1608 93.3611 23.8736 93.3611 24.7167C93.3611 25.5348 93.5403 26.2365 93.9034 26.8207C94.3405 27.5288 94.9511 27.882 95.739 27.882C96.5108 27.882 97.1167 27.5214 97.5538 26.8022C97.9264 26.206 98.1141 25.4969 98.1141 24.6797Z"
        fill="white"
      />
      <path
        d="M106.782 21.9867C106.582 21.9507 106.368 21.9322 106.145 21.9322C105.434 21.9322 104.884 22.1938 104.497 22.718C104.16 23.1802 103.991 23.7644 103.991 24.4698V29.1244H101.971L101.99 23.0471C101.99 22.0246 101.964 21.0937 101.914 20.2543H103.675L103.749 21.9516H103.805C104.018 21.3683 104.355 20.8987 104.815 20.5465C105.266 20.2294 105.752 20.0713 106.277 20.0713C106.463 20.0713 106.632 20.0842 106.782 20.1073V21.9867Z"
        fill="white"
      />
      <path
        d="M115.823 24.2692C115.823 24.6224 115.799 24.92 115.749 25.1632H109.684C109.708 26.0395 110.001 26.7098 110.564 27.172C111.075 27.5852 111.736 27.7923 112.548 27.7923C113.446 27.7923 114.265 27.6527 115.002 27.3726L115.318 28.7408C114.457 29.1068 113.441 29.289 112.268 29.289C110.857 29.289 109.75 28.8841 108.944 28.0752C108.14 27.2663 107.737 26.1801 107.737 24.8174C107.737 23.4798 108.111 22.3658 108.861 21.4774C109.646 20.5289 110.707 20.0547 112.042 20.0547C113.354 20.0547 114.346 20.5289 115.02 21.4774C115.554 22.2308 115.823 23.1627 115.823 24.2692ZM113.895 23.758C113.908 23.1738 113.777 22.669 113.502 22.2428C113.153 21.6947 112.615 21.421 111.892 21.421C111.231 21.421 110.693 21.6882 110.283 22.2244C109.946 22.6505 109.746 23.1617 109.684 23.7571H113.895V23.758Z"
        fill="white"
      />
      <path
        d="M46.507 9.25214C46.507 10.3402 46.1723 11.1593 45.5038 11.7093C44.8847 12.2168 44.0048 12.4711 42.8651 12.4711C42.3 12.4711 41.8165 12.447 41.4116 12.399V6.45385C41.9397 6.37065 42.5086 6.32812 43.123 6.32812C44.2087 6.32812 45.0269 6.55831 45.5787 7.01868C46.1969 7.53915 46.507 8.28333 46.507 9.25214ZM45.4593 9.27895C45.4593 8.5736 45.2677 8.0328 44.8847 7.65563C44.5016 7.27938 43.9422 7.09079 43.2055 7.09079C42.8926 7.09079 42.6262 7.11113 42.4053 7.15365V11.6733C42.5276 11.6918 42.7514 11.7001 43.0766 11.7001C43.837 11.7001 44.4239 11.4939 44.8373 11.0816C45.2507 10.6693 45.4593 10.0684 45.4593 9.27895Z"
        fill="white"
      />
      <path
        d="M52.0622 10.2033C52.0622 10.8735 51.8659 11.4226 51.4734 11.8534C51.0619 12.2962 50.5167 12.5172 49.8359 12.5172C49.1798 12.5172 48.6574 12.3055 48.2677 11.8802C47.8789 11.4559 47.6846 10.9207 47.6846 10.2754C47.6846 9.60055 47.8846 9.04681 48.2866 8.61694C48.6887 8.18708 49.2291 7.97168 49.9099 7.97168C50.566 7.97168 51.0932 8.18338 51.4923 8.6077C51.8716 9.02 52.0622 9.55248 52.0622 10.2033ZM51.0315 10.2347C51.0315 9.83259 50.9424 9.48777 50.7651 9.20027C50.5565 8.85267 50.2597 8.67888 49.8738 8.67888C49.4747 8.67888 49.1713 8.85267 48.9627 9.20027C48.7844 9.48777 48.6962 9.83813 48.6962 10.2523C48.6962 10.6544 48.7854 10.9992 48.9627 11.2867C49.1779 11.6343 49.4775 11.8081 49.8644 11.8081C50.2436 11.8081 50.5413 11.6316 50.7556 11.2775C50.9396 10.9844 51.0315 10.6369 51.0315 10.2347Z"
        fill="white"
      />
      <path
        d="M59.5105 8.05957L58.112 12.4174H57.2018L56.6225 10.5251C56.4755 10.0527 56.356 9.58305 56.2631 9.11713H56.2451C56.1588 9.596 56.0393 10.0647 55.8857 10.5251L55.2704 12.4174H54.3497L53.0347 8.05957H54.0558L54.5612 10.1313C54.6835 10.6212 54.784 11.0881 54.8646 11.5299H54.8826C54.9566 11.1657 55.0789 10.7016 55.2514 10.1405L55.8857 8.0605H56.6955L57.3032 10.0961C57.4502 10.5925 57.5697 11.0705 57.6616 11.5309H57.6891C57.7564 11.0825 57.8579 10.6046 57.9925 10.0961L58.5349 8.0605H59.5105V8.05957Z"
        fill="white"
      />
      <path
        d="M64.6619 12.4181H63.6682V9.9221C63.6682 9.15296 63.3686 8.76839 62.7675 8.76839C62.4726 8.76839 62.2346 8.87378 62.0497 9.08548C61.8667 9.29718 61.7738 9.54678 61.7738 9.83243V12.4172H60.7802V9.3055C60.7802 8.92278 60.7678 8.5077 60.7441 8.05842H61.6174L61.6638 8.73974H61.6913C61.807 8.52804 61.9796 8.35332 62.2062 8.21373C62.4755 8.05103 62.777 7.96875 63.1069 7.96875C63.5241 7.96875 63.8711 8.10002 64.147 8.36349C64.4903 8.68612 64.6619 9.16776 64.6619 9.80747V12.4181V12.4181Z"
        fill="white"
      />
      <path d="M67.4019 12.418H66.4092V6.06055H67.4019V12.418Z" fill="white" />
      <path
        d="M73.2521 10.2033C73.2521 10.8735 73.0558 11.4226 72.6633 11.8534C72.2518 12.2962 71.7057 12.5172 71.0259 12.5172C70.3688 12.5172 69.8464 12.3055 69.4576 11.8802C69.0689 11.4559 68.8745 10.9207 68.8745 10.2754C68.8745 9.60055 69.0746 9.04681 69.4766 8.61694C69.8786 8.18708 70.419 7.97168 71.0989 7.97168C71.7559 7.97168 72.2822 8.18338 72.6823 8.6077C73.0615 9.02 73.2521 9.55248 73.2521 10.2033ZM72.2205 10.2347C72.2205 9.83259 72.1314 9.48777 71.9541 9.20027C71.7465 8.85267 71.4487 8.67888 71.0638 8.67888C70.6637 8.67888 70.3603 8.85267 70.1526 9.20027C69.9744 9.48777 69.8862 9.83813 69.8862 10.2523C69.8862 10.6544 69.9753 10.9992 70.1526 11.2867C70.3678 11.6343 70.6675 11.8081 71.0543 11.8081C71.4336 11.8081 71.7303 11.6316 71.9446 11.2775C72.1295 10.9844 72.2205 10.6369 72.2205 10.2347Z"
        fill="white"
      />
      <path
        d="M78.0614 12.4183H77.1692L77.0953 11.9163H77.0678C76.7625 12.3166 76.3273 12.5172 75.7622 12.5172C75.3402 12.5172 74.9989 12.385 74.742 12.1224C74.5087 11.8839 74.3921 11.5872 74.3921 11.235C74.3921 10.7025 74.6196 10.2967 75.0776 10.0156C75.5346 9.73459 76.1775 9.59685 77.0052 9.60332V9.52197C77.0052 8.94789 76.6961 8.66132 76.0769 8.66132C75.6361 8.66132 75.2473 8.76948 74.9117 8.98395L74.7097 8.34793C75.125 8.0974 75.638 7.97168 76.2429 7.97168C77.411 7.97168 77.9969 8.57257 77.9969 9.77435V11.3792C77.9969 11.8146 78.0188 12.1613 78.0614 12.4183ZM77.0298 10.9207V10.2486C75.9338 10.2301 75.3858 10.5231 75.3858 11.1268C75.3858 11.3542 75.4483 11.5243 75.5763 11.638C75.7043 11.7517 75.8674 11.8081 76.0618 11.8081C76.2799 11.8081 76.4837 11.7406 76.6695 11.6066C76.8563 11.4716 76.971 11.3006 77.0137 11.0908C77.0241 11.0436 77.0298 10.9863 77.0298 10.9207Z"
        fill="white"
      />
      <path
        d="M83.7071 12.418H82.8253L82.7789 11.7181H82.7514C82.4698 12.2506 81.99 12.5169 81.3159 12.5169C80.7773 12.5169 80.3288 12.3107 79.9733 11.8984C79.6177 11.4861 79.4404 10.9509 79.4404 10.2936C79.4404 9.58823 79.6329 9.01692 80.0197 8.58058C80.3943 8.17383 80.8532 7.97045 81.3993 7.97045C81.9995 7.97045 82.4195 8.16736 82.6584 8.56209H82.6774V6.06055H83.672V11.2439C83.672 11.6682 83.6834 12.0593 83.7071 12.418ZM82.6774 10.5802V9.85354C82.6774 9.72782 82.6679 9.62613 82.6499 9.54848C82.594 9.31552 82.4735 9.11953 82.2906 8.96145C82.1057 8.80337 81.8828 8.72387 81.6259 8.72387C81.2552 8.72387 80.965 8.86716 80.7517 9.15466C80.5403 9.44216 80.4331 9.80917 80.4331 10.2575C80.4331 10.6883 80.5346 11.0378 80.7384 11.3068C80.9537 11.5933 81.2438 11.7366 81.6069 11.7366C81.9331 11.7366 82.1938 11.6174 82.392 11.378C82.5835 11.157 82.6774 10.8908 82.6774 10.5802Z"
        fill="white"
      />
      <path
        d="M92.2057 10.2033C92.2057 10.8735 92.0095 11.4226 91.6169 11.8534C91.2054 12.2962 90.6612 12.5172 89.9795 12.5172C89.3243 12.5172 88.8019 12.3055 88.4112 11.8802C88.0225 11.4559 87.8281 10.9207 87.8281 10.2754C87.8281 9.60055 88.0282 9.04681 88.4302 8.61694C88.8322 8.18708 89.3727 7.97168 90.0544 7.97168C90.7095 7.97168 91.2377 8.18338 91.6359 8.6077C92.0151 9.02 92.2057 9.55248 92.2057 10.2033ZM91.176 10.2347C91.176 9.83259 91.0869 9.48777 90.9096 9.20027C90.7001 8.85267 90.4042 8.67888 90.0174 8.67888C89.6192 8.67888 89.3158 8.85267 89.1062 9.20027C88.928 9.48777 88.8398 9.83813 88.8398 10.2523C88.8398 10.6544 88.9289 10.9992 89.1062 11.2867C89.3215 11.6343 89.6211 11.8081 90.0079 11.8081C90.3872 11.8081 90.6858 11.6316 90.9001 11.2775C91.0831 10.9844 91.176 10.6369 91.176 10.2347Z"
        fill="white"
      />
      <path
        d="M97.5486 12.4181H96.5559V9.9221C96.5559 9.15296 96.2563 8.76839 95.6542 8.76839C95.3594 8.76839 95.1214 8.87378 94.9374 9.08548C94.7535 9.29718 94.6615 9.54678 94.6615 9.83243V12.4172H93.6669V9.3055C93.6669 8.92278 93.6555 8.5077 93.6318 8.05842H94.5041L94.5506 8.73974H94.5781C94.6947 8.52804 94.8673 8.35332 95.0929 8.21373C95.3632 8.05103 95.6637 7.96875 95.9946 7.96875C96.4109 7.96875 96.7579 8.10002 97.0338 8.36349C97.378 8.68612 97.5486 9.16776 97.5486 9.80747V12.4181V12.4181Z"
        fill="white"
      />
      <path
        d="M104.236 8.7856H103.142V10.9026C103.142 11.4406 103.336 11.7096 103.721 11.7096C103.899 11.7096 104.047 11.6948 104.164 11.6643L104.189 12.3992C103.993 12.4714 103.735 12.5074 103.418 12.5074C103.025 12.5074 102.72 12.3909 102.499 12.158C102.277 11.925 102.167 11.533 102.167 10.983V8.7856H101.514V8.05991H102.167V7.26119L103.141 6.97461V8.05898H104.235V8.7856H104.236Z"
        fill="white"
      />
      <path
        d="M109.496 12.418H108.501V9.94044C108.501 9.15928 108.202 8.76824 107.602 8.76824C107.141 8.76824 106.826 8.99473 106.653 9.44771C106.624 9.54293 106.607 9.65941 106.607 9.79623V12.417H105.614V6.06055H106.607V8.68689H106.626C106.939 8.20896 107.387 7.97045 107.969 7.97045C108.38 7.97045 108.72 8.10172 108.991 8.36519C109.327 8.69336 109.496 9.18147 109.496 9.82673V12.418V12.418Z"
        fill="white"
      />
      <path
        d="M114.922 10.0327C114.922 10.2065 114.909 10.3526 114.885 10.4718H111.905C111.919 10.9026 112.061 11.2308 112.337 11.4582C112.589 11.6616 112.914 11.7633 113.312 11.7633C113.753 11.7633 114.155 11.6949 114.518 11.5571L114.673 12.2301C114.249 12.4095 113.751 12.4991 113.173 12.4991C112.481 12.4991 111.936 12.3004 111.541 11.9029C111.145 11.5054 110.949 10.972 110.949 10.3027C110.949 9.64538 111.132 9.09811 111.501 8.66177C111.886 8.19585 112.406 7.96289 113.063 7.96289C113.706 7.96289 114.194 8.19585 114.524 8.66177C114.791 9.03155 114.922 9.48915 114.922 10.0327ZM113.974 9.7822C113.982 9.49469 113.916 9.24694 113.782 9.03802C113.609 8.76901 113.347 8.63404 112.991 8.63404C112.667 8.63404 112.402 8.76531 112.2 9.02877C112.035 9.23862 111.938 9.48915 111.905 9.7822H113.974Z"
        fill="white"
      />
    </svg>
  );
};
