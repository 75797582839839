import { getCognitoErrorText, ICognitoErrorTextOptions } from '@hub/cognito-auth/Auth.utils';
import { i18n, MessageDescriptor } from '@lingui/core';

export function translate(value: MessageDescriptor): string;
export function translate(value: string): string;
export function translate(value: MessageDescriptor | string): string {
  if (typeof value !== "string" || (value as unknown as MessageDescriptor).id) {
    return i18n._(value as MessageDescriptor);
  }

  return (value ? i18n._(value as string) : value)
}

export const getCognitoTranslatedErrorText = (error: unknown, options?: ICognitoErrorTextOptions) =>
  translate(getCognitoErrorText(error, options));

export const withTranslatedProperty = <T>(object: T | T[], property: keyof T): any => {
  if (Array.isArray(object)) {
    return object.map(item => ({ ...item, [property]: i18n._(item[property] as any) }))
  }
  return { ...object, [property]: i18n._(object[property] as any) }
}

