import React, { useEffect } from 'react';
import { FieldValues, FormProvider, UseFormReturn, useWatch } from 'react-hook-form';
import { FormProps, Form as FormWrapper } from '@hub/ui';

interface IFormOnChangeListenerProps<T extends FieldValues> {
  onChange: (values: Partial<T>) => void;
}

const FormOnChangeListener = <T extends FieldValues>({
  onChange,
}: IFormOnChangeListenerProps<T>) => {
  const values = useWatch();

  useEffect(() => {
    onChange(values as unknown as Partial<T>);
  }, [values]);

  return null;
};

export function Form<T extends FieldValues>({
  methods,
  onChange,
  ...rest
}: { methods: UseFormReturn<T>; onChange?: (values: Partial<T>) => void } & FormProps) {
  return (
    <FormProvider {...methods}>
      {onChange && <FormOnChangeListener onChange={onChange} />}

      <FormWrapper {...rest} />
    </FormProvider>
  );
}
