import { useState, MouseEvent, ReactNode } from 'react';
import { Eye, EyeOff } from '@hub/ui';

export const useTogglePasswordVisibility = () => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const handlePasswordInputType = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setPasswordVisible(prev => !prev);
  };

  const getPasswordIcon = (): ReactNode => {
    return (
      <button type="button" onClick={handlePasswordInputType}>
        {isPasswordVisible ? <EyeOff className="w-4" /> : <Eye className="w-4" />}
      </button>
    );
  };

  return {
    isPasswordVisible,
    getPasswordIcon,
  };
};
