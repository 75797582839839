import React from 'react';
interface ChronoLogoLargeProps {
  className?: string;
}

export const ChronoLogoLarge: React.FC<ChronoLogoLargeProps> = ({ className }) => {
  return (
    <svg
      width="200"
      height="36"
      viewBox="0 0 200 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M194.074 14.4674H179.759V31.0724H174.493V14.4674C174.493 11.8106 176.64 9.61359 179.35 9.61359H189.217C191.927 9.61359 194.074 11.8106 194.074 14.4674ZM194.074 31.0724V14.4674H199.34V31.0724C199.34 33.7293 197.192 35.8751 194.534 35.8751H184.565C181.906 35.8751 179.759 33.7293 179.759 31.0724H194.074Z"
        fill="#4F4F4F"
      />
      <path
        d="M155.21 9.61359H163.543C166.201 9.61359 168.349 11.8106 168.349 14.4674V35.8751H163.083V14.4674H150.404C150.404 11.8106 152.551 9.61359 155.21 9.61359ZM150.404 35.8751H145.087V14.4674H150.404V35.8751Z"
        fill="#4F4F4F"
      />
      <path
        d="M133.663 14.4674H119.348V31.0724H114.083V14.4674C114.083 11.8106 116.23 9.61359 118.939 9.61359H128.806C131.516 9.61359 133.663 11.8106 133.663 14.4674ZM133.663 31.0724V14.4674H138.929V31.0724C138.929 33.7293 136.782 35.8751 134.123 35.8751H124.154C121.496 35.8751 119.348 33.7293 119.348 31.0724H133.663Z"
        fill="#4F4F4F"
      />
      <path
        d="M95.6371 9.61359V14.4163H107.958V9.61359H95.6371ZM90.2179 35.8751V19.6788C90.2179 16.7665 92.6207 14.4163 95.5348 14.4163V35.8751H90.2179Z"
        fill="#00CF91"
      />
      <path
        d="M60.7614 5.52616V0.110352H66.0783V10.7887C63.1643 10.7887 60.7614 8.43843 60.7614 5.52616ZM66.0783 10.7887H78.9617C81.7736 10.7887 84.0742 13.0879 84.0742 15.8979V35.8751H78.7572V15.8979H66.0783V10.7887ZM66.0783 35.8751H60.7614V15.8979H66.0783V35.8751Z"
        fill="#4F4F4F"
      />
      <path
        d="M44.3231 9.61359H54.4969V14.4674H39.4663C39.4663 11.7595 41.6135 9.61359 44.3231 9.61359ZM39.4663 14.4674L39.5174 30.9703H54.6503V35.824H39.5174C36.5522 35.824 34.2005 33.5249 34.2005 30.6126V14.4674H39.4663Z"
        fill="#4F4F4F"
      />
      <path
        d="M0 14.1391C0 11.6119 2.05003 9.56312 4.57886 9.56312H26.3285V31.2991C26.3285 33.8264 24.2784 35.8751 21.7496 35.8751H4.57886C2.05003 35.8751 0 33.8264 0 31.2991V14.1391Z"
        fill="#00CF91"
      />
      <path
        d="M12.5532 21.3054V23.1016H8.95845V25.4194H12.9735V27.2301H6.74072V17.4666H12.7706V19.2774H8.95845V21.3054H12.5532Z"
        fill="#4F4F4F"
      />
      <path d="M14.4836 27.2301V17.4666H16.7014V25.3759H20.586V27.2301H14.4836Z" fill="#4F4F4F" />
    </svg>
  );
};
