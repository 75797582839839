import React from 'react';
interface ChronoLogoSmallProps {
  className?: string;
}

export const ChronoLogoSmall: React.FC<ChronoLogoSmallProps> = ({ className }) => {
  return (
    <svg
      width="45"
      height="44"
      viewBox="0 0 45 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1 14.608C1 11.612 1.16119 9.23857 1.57892 7.35895C1.99539 5.485 2.66017 4.13267 3.64642 3.14642C4.63267 2.16017 5.985 1.49539 7.85895 1.07892C9.73857 0.661186 12.112 0.5 15.108 0.5H29.892C32.888 0.5 35.2614 0.661186 37.1411 1.07892C39.015 1.49539 40.3673 2.16017 41.3536 3.14642C42.3398 4.13267 43.0046 5.485 43.4211 7.35895C43.8388 9.23857 44 11.612 44 14.608V29.392C44 32.388 43.8388 34.7614 43.4211 36.6411C43.0046 38.515 42.3398 39.8673 41.3536 40.8536C40.3673 41.8398 39.015 42.5046 37.1411 42.9211C35.2614 43.3388 32.888 43.5 29.892 43.5H15.108C12.112 43.5 9.73857 43.3388 7.85895 42.9211C5.985 42.5046 4.63267 41.8398 3.64642 40.8536C2.66017 39.8673 1.99539 38.515 1.57892 36.6411C1.16119 34.7614 1 32.388 1 29.392V14.608Z"
        fill="url(#paint0_linear_2037_103390)"
        stroke="#DAE2EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.0309 16.4367V11.2012L19.5597 11.2012L19.5597 16.4367L19.5612 16.4367C16.7768 16.4367 14.3241 18.6978 14.3241 21.3053V33.8887H19.5612V16.4367H30.0309Z"
        fill="#00CF91"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2037_103390"
          x1="3.14"
          y1="-1.76"
          x2="48.68"
          y2="54.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#F1F1F1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
