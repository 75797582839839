import {
  AppStoreLogo,
  Button,
  ChronoLogoLarge,
  ChronoLogoSmall,
  GooglePlayLogo,
  Link,
} from '@hub/ui';
import React from 'react';
import { Trans } from '@lingui/macro';
import { Icon } from '@elpassion/taco';
import { useRouter } from 'next/router';
import { routes } from '../../../../frontend/utils';

interface IAuthLayoutProps {
  children: React.ReactNode;
  boxContent: React.ReactNode;
}

export const AuthLayout: React.FC<IAuthLayoutProps> = ({ children, boxContent }) => {
  const router = useRouter();

  return (
    <div className="w-full min-h-screen grid grid-cols-1 2xl:grid-cols-[3fr_5fr] 3xl:grid-cols-[1fr_2fr]">
      {router.route === routes.forgotPassword && (
        <div className="absolute z-10 px-4 py-6">
          <Button
            variant="outlined"
            hierarchy="tertiary"
            size="md"
            onClick={() => router.push(routes.login)}
            leftIcon={<Icon iconName="chevron-left" className="!leading-4" />}
          >
            <Trans>Back</Trans>
          </Button>
        </div>
      )}
      <main className="relative p-4 mx-auto w-full h-full flex items-center justify-center">
        <div className="w-full max-w-xs flex flex-col items-center ">
          <div className="mb-6">
            <ChronoLogoSmall className="lg:hidden" />
            <ChronoLogoLarge className="hidden lg:block" />
          </div>
          {children}
        </div>
      </main>
      <div className="hidden relative w-full h-full bg-neutral-100 2xl:block">
        <video
          autoPlay
          muted
          loop
          playsInline
          id="myVideo"
          className="object-cover h-full w-full absolute"
          style={{ objectPosition: 'left' }}
        >
          <source src="/signinup_background_video.mp4" type="video/mp4" />
        </video>
        <div className="absolute top-[11%] left-[8.5%] p-14 bg-white">
          {boxContent}
          <svg
            height="50"
            width="100"
            viewBox="100 50 100 50"
            className="absolute -bottom-12 left-14"
          >
            <ellipse cx="100" cy="50" rx="21" ry="22" style={{ fill: 'white' }} />
          </svg>
        </div>
        <div className="absolute right-24 bottom-10 ">
          <div className="flex gap-4">
            <Link
              target="_blank"
              href="https://apps.apple.com/us/app/el-chrono/id6466168043"
              prefetch={false}
            >
              <AppStoreLogo className="w-32" />
            </Link>
            <Link
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.elpassion.hub&hl=en&gl=US"
              prefetch={false}
            >
              <GooglePlayLogo className="w-32" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
