import { forwardRef } from 'react';
import { Input as TacoInput } from '@elpassion/taco';
import { InputProps } from '@elpassion/taco';

export type TInputProps = InputProps;

export const Input = forwardRef<HTMLInputElement, TInputProps>((props, ref) => {
  const { type = 'text', ...rest } = props;

  return <TacoInput ref={ref} type={type} {...rest} />;
});
