import React from 'react';
import { i18n } from '@lingui/core';
import { Input, TInputProps } from '@hub/ui';
import { useFieldContext } from '../Form';

export type InputFieldProps = Omit<TInputProps, 'id'> & {
  dataCy?: string;
};

export function InputField(props: InputFieldProps) {
  const {
    field,
    fieldState: { error },
  } = useFieldContext();

  const message = error?.message ? i18n._(error.message) : undefined;
  const inputProps = { ...props };
  delete inputProps.dataCy;

  return (
    <Input
      id={field.name}
      errorMessage={message}
      {...field}
      {...inputProps}
      data-cy={props.dataCy}
    />
  );
}
